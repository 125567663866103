<script>
import moment from 'moment';

export default {
    name: 'CopyrightFooter',
    date() {
        return {
            currentYear: null,
        }
    },
    created() {
        this.currentYear = moment().format('YYYY');
    },
}
</script>

<template>
    <div>
        <span class="copyrightText">Pepperzap – {{ currentYear }} | Todos os direitos reservados.</span>
    </div>
</template>

<style scoped>

.copyrightText {
  display: block;
  font-size: 0.875rem;
  color: #9397ad;
  text-align: center;
  margin: auto;
  padding: 1.5rem 0px 3rem;
}

    
@media screen and (max-width: 995px) {
.copyrightText {
font-size: 0.75rem;
}
}

</style>