
<script>
import routes from '../router/index';
export default {
    name: 'SideBar',
    data() {
        return {
            permissionStatus: {
                all_messages: Boolean,
                edit_messages: Boolean,
                collaborators: Boolean,
                report: Boolean,
                integrations: Boolean,
                settings: Boolean,
            },
            quantity: '',
        }
    },
    methods: {
        async counTotal() {
            $('.loaderPoints.loader-white').show(200)
            $('.countTotal').hide(200)
            await this.$axios.get(`/transactions/quantity`)
            .then(req => {
                this.quantity = req.data.data
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                $('.loaderPoints.loader-white').hide(200)
                $('.countTotal').show(200)
            });
        },
        saveAsideActive() {
            const asideActive = localStorage.getItem('asideActive')

            if (asideActive == 'true') {
                $('body').addClass('asideOpen')
            }
          
        },
        logout() {
            localStorage.removeItem('access_token')
            routes.push('/login');
        },
        asideMobile() {
            this.counTotal()
            $('body').toggleClass('asideOpen')
            localStorage.removeItem('asideActive')

            var $window = $(window),
            $body = $('body');

            function resize() {
                const asideActive = localStorage.getItem('asideActive')
                if ($window.width() < 1051 || asideActive == 'false') {
                    $body.removeClass('asideOpen');
                    
                }
            }
            $window
                .resize(resize)
                .trigger('resize');
        },
        identifyPermission() {
           this.collaborators = (localStorage.getItem('permissions').indexOf('collaborators') != -1)
           this.all_messages = (localStorage.getItem('permissions').indexOf('all_messages') != -1)
           this.edit_messages = (localStorage.getItem('permissions').indexOf('edit_messages') != -1)
           this.report = (localStorage.getItem('permissions').indexOf('report') != -1)
           this.integrations = (localStorage.getItem('permissions').indexOf('integrations') != -1)
           this.settings = (localStorage.getItem('permissions').indexOf('settings') != -1)
        },
    },
    beforeMount(){
        this.saveAsideActive()
    },
    created() {
        this.identifyPermission()
        this.counTotal()
    }
    
}

</script>

<template>
    <div>
       <aside>
        <router-link to="/atendimento" class="primary-logo-icon">
        </router-link>
        <span v-on:click="asideMobile" class="closeButton">
        </span>

        <nav>
            <router-link @click="asideMobile" to="/atendimento" class="serviceLink">
                Atendimento 
                <span class="counterService">
                    <div class="countTotal">
                        {{ quantity.total }}
                    </div>
                    <div class="loaderPoints loader-white">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </span>
            </router-link>
            <router-link v-if="all_messages" @click="asideMobile" to="/mensagens" class="allMessagesLink">Todas as mensagens</router-link>
            <router-link v-if="edit_messages" @click="asideMobile" to="/mensagens/editar" class="editMessagesLink">Editar mensagens</router-link>
            <router-link v-if="collaborators" @click="asideMobile" to="/colaboradores" class="collaboratorsLink">Colaboradores</router-link>
            <!-- <router-link v-if="report" @click="asideMobile" to="/relatorios" class="reportsLink">Relatórios</router-link> -->
            <router-link v-if="integrations" @click="asideMobile" to="/integracoes" class="integrationsLink">Integrações</router-link>
            <!-- <router-link v-if="settings" @click="asideMobile" to="/" class="settingsLink">Configurações</router-link> -->
        </nav>

        <button v-on:click="logout" class="logoutButton">Desconectar</button>
       </aside>
       <div v-on:click="asideMobile" class="fade"></div>
    </div>
</template>



<style scoped>
    aside {
        width: var(--largeSideBar);
        height: 100vh;
        background-color: #140F22;
        position: fixed;
        top: 0px;
        left: calc(0px - var(--largeSideBar));
        z-index: 65;
        display: flex;
        flex-direction: column;
        transition: 0.4s ease;
        overflow-y: scroll;
        border-right: 1px solid var(--dark-grey);
    }

    ::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }
    body.asideOpen aside {
        left: 0px;
    }

    aside .primary-logo-icon {
        left: auto;
        top: auto;
        max-width: 65%;
        margin: 30px 1.5rem 1.125rem;
        position: relative;
        width: 150px;
        min-height: 25px;
    }

    aside nav {
        display: flex;
        flex-direction: column;
        margin: 20px 0px 35px;
    }
    
    aside nav a {
        border-color: rgba(255, 255, 255, 0.15);
        color: rgba(255, 255, 255, 0.85);
        font-weight: 400;
        text-align: inherit;
        padding: 0.75rem 1rem;
        text-decoration: none;
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        font-size: 14px;
    }

    aside nav a::before {
        content: '';
        height: 100%;
        width: 3px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: transparent;
        transition: .4s ease all;
    }

    aside nav a:hover::before {
        background-color: var(--converzap-green-main);
    }

    aside nav a:hover {
        color: var(--converzap-green-main);
        background-color: rgb(4 249 44 / 10%);
    }

    aside nav a:after {
        content: '';
        background-repeat: no-repeat;
        background-size: 17px;
        background-position: center;
        width: 35px;
        height: 20px;
        order: -1;
        transition: .4s ease all;
    }

    aside nav a.serviceLink:after {
        background-image: url('../assets/grey-messages.svg');
    }
    aside nav a.serviceLink:hover::after {
        background-image: url('../assets/green-messages.svg');
    }

    aside nav a.allMessagesLink:after {
        background-image: url('../assets/grey-search.svg');
    }
    aside nav a.allMessagesLink:hover:after {
        background-image: url('../assets/green-search.svg');
    }

    aside nav a.editMessagesLink:after {
        background-image: url('../assets/grey-edit-message.svg');
    }

    aside nav a.editMessagesLink:hover:after {
        background-image: url('../assets/green-edit-message.svg');
    }

    aside nav a.attendantsLink:after {
        background-image: url('../assets/grey-atendentes.svg');
        background-size: 20px;
    }

    aside nav a.attendantsLink:hover:after {
        background-image: url('../assets/green-atendentes.svg');
    }
    aside nav a.collaboratorsLink:after {
        background-image: url('../assets/bx-user-plus-grey.svg');
        background-size: 20px;
    }

    aside nav a.collaboratorsLink:hover:after {
        background-image: url('../assets/bx-user-plus-green.svg');
    }

    aside nav a.reportsLink:after {
        background-image: url('../assets/grey-grafico.svg');
    }

    aside nav a.reportsLink:hover:after {
        background-image: url('../assets/green-grafico.svg');
    }

    aside nav a.integrationsLink:after {
        background-image: url('../assets/grey-link.svg');
    }

    aside nav a.integrationsLink:hover:after {
        background-image: url('../assets/green-link.svg');
    }

    aside nav a.settingsLink:after {
        background-image: url('../assets/grey-configuracao.svg');
    }

    aside nav a.settingsLink:hover:after {
        background-image: url('../assets/green-configuracao.svg');
    }


    .counterService {
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
        display: inline-block;
        padding: 0.35em 0.6em;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 50rem;
        margin-left: auto;
        display: flex;
        align-items: baseline;
    }

    .counterService .countTotal {
        font-size: 0.75rem !important;
        font-weight: 600;
        line-height: 1;
        color: #fff;
        text-align: center;
    }

    .loaderSpinner {
        margin: 0px;
        width: 12px;
        height: 12px;
    }

    .logoutButton {
        color: var(--converzap-green-black) !important;
        background-color: var(--converzap-green-main);
        border-color: var(--converzap-green-main);
        transition: all .3s ease;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: auto auto 40px;
        width: calc(100% - 3rem);
        line-height: 1.6;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        vertical-align: middle;
        -moz-user-select: none;
        -webkit-appearance: none;
        user-select: none;
        border: 1px solid transparent;
        padding: 0.625rem 1.75rem;
        font-size: 0.9rem;
        border-radius: 0.375rem;
        font-weight: 700;
        display: flex;
        align-items: center;
    }

    .logoutButton:hover {
        background-color: var(--converzap-green-faded);
        border-color: var(--converzap-green-faded);
    }

    .logoutButton::before {
        content: '';
        background-image: url('../assets/noun-log-out-green-black.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 18px;
        height: 17px;
        display: inline-block;
        margin-right: 10px;
    }

    .fadeAside {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 40;
        width: 100vw;
        height: 100vh;
        background-color: #000;
        opacity: 0.65;
        transition: opacity 0.15s linear;
    }
    body:not(.asideOpen) .fadeAside {
        opacity: 0;
        z-index: -1;
    }

    @media screen and (max-width: 995px) {
    .fadeAside {
    display: block
    }


}


</style>