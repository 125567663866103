import { createApp, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import VueAxios from 'vue-axios';



axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.request.use(async (config) => { 
    config.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return config;
});

axios.interceptors.response.use((response) => {
    return Promise.resolve(response);
  }, (error) => {
    if (
      error.response.data.message == "Unauthenticated." ||
      error.response.status == 401
    ) {

        localStorage.removeItem('firstName');
        localStorage.removeItem('access_token');
        localStorage.removeItem('must_reset_password');

      router.replace({
          path: "/login",
          query: { redirect: router.currentRoute.fullPath }
      });
    }
    return Promise.reject(error);
});

const api = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
})

const app = createApp(App)

app.use(router)
app.use(VueAxios, axios)
app.config.globalProperties.$axios = axios;
app.mount('#app');
