import { createRouter, createWebHistory } from 'vue-router'
import Attendance from '../pages/Attendance'
import DefaultPage from '../pages/DefaultPage'




const routes = [
  {
    path: '/:catchAll(.*)', redirect:'login'
  },
  {
    path: '/',
    redirect: '/atendimento',
    name: 'DefaultPage',
    component: DefaultPage,
    children: [
      {
        path: '/atendimento',
        name: 'Attendance',
        component: Attendance
      },
      {
        path: '/mensagens/editar',
        name: 'EditMessage',
        component: () => import('../pages/EditMessage.vue')
      },
      {
        path: '/mensagens',
        name: 'AllMessages',
        component: () => import('../pages/AllMessages.vue')
      },
      {
        path: '/colaboradores',
        name: 'Collaborators',
        component: () => import('../pages/Collaborators.vue')
      },
      {
        path: '/integracoes',
        name: 'Integrations',
        component: () => import('../pages/Integrations.vue')
      },
      {
        path: '/relatorios',
        name: 'Reports',
        component: () => import('../pages/Reports.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../pages/Login.vue')
  },
  {
    path: '/redefinicao-de-senha',
    name: 'Reset password',
    component: () => import('../pages/ResetPassword.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
