<script>
import CardInformation from './CardInformation.vue'

export default {
  name: 'Tabs',
  components: {
    CardInformation
  },
  data() {
    return {
      activeTab: 1,
      quantity: '',
      cardData: {},
      emptyState: true,
      ording: 0,
    }
  },
  methods: {
    async clickTab(id) {
      this.loaderActive()
      $(".loaderPoints").show(200);
      this.emptyState = false; 
      this.activeTab = id
      if (this.activeTab == 1) {
        this.data = { status: "waitingpayment", method: "billet", order_by: 'date', order_type: 'ASC' }
      } else if (this.activeTab == 2) {
        this.data = { status: "waitingpayment", method: "pix", order_by: 'date', order_type: 'DESC' }
      } else if (this.activeTab == 3) {
        this.data = { status: "abandonment" }
      } else if (this.activeTab == 4) {
        this.data = { status: "refused" }
      } else if (this.activeTab == 5) {
        this.data = { status: "paid" }
      }

      if (this.ording == 0) {
        this.data.order_by = 'date'
        this.data.order_type = 'ASC'
      } else if (this.ording == 1) {
        this.data.order_by = 'date'
        this.data.order_type = 'DESC'
      } else if (this.ording == 2) {
        this.data.order_by = 'price'
        this.data.order_type = 'DESC'
      }  else if (this.ording == 3) {
        this.data.order_by = 'price'
        this.data.order_type = 'ASC'
      }

      await this.$axios.get('/transactions', {
        params: this.data
      })
      .then(req => {
        this.cardData = req.data.data;
        this.count()
        this.emptyState = true;
      }).catch((err) => {
        this.emptyState = false;
      }).finally(() => {
        this.loaderDisabled()
      });
    },

    async count() {
      $(".loaderPoints").show(200);
      await this.$axios.get(`/transactions/quantity`)
      .then(req => {
        this.quantity = req.data.data
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        $(".loaderPoints").hide(200);
      });
    },

    changeOrder(order) {
      this.ording = order
      this.clickTab(this.activeTab)
    },

    loaderActive() {
        $(".loaderSpinner").show(200);
        $('.countTotal').hide(200)
        $(".empty-status").hide(200);
        $(".count").hide(200);
    },

    loaderDisabled() {
        $(".loaderSpinner").hide(200);
        $(".loaderPoints").hide(200);
        $(".empty-status").show(200);
        $('.countTotal').show(200)
        $(".count").show(200);
    },

  },

  created() {
    this.count()
    this.clickTab(1)
  },


}
</script>

<template>
  <div>
    <div class="containerTab">
      <ul class="tabs-choose">
        <li @click="clickTab(1)" :class="[activeTab == 1 ? 'active' : '']">
          Boletos gerados 
          (<span class="count">
            {{ quantity.billetPrinted_count }}
          </span>
          <div class="loaderPoints">
            <span></span>
            <span></span>
            <span></span>
          </div>)
          
        </li>
        <li @click="clickTab(2)" :class="[activeTab == 2 ? 'active' : '']">
          PIX gerados 
          (<span class="count">
            {{ quantity.pixGenerated_count }}
          </span>
          <div class="loaderPoints">
            <span></span>
            <span></span>
            <span></span>
          </div>)
          
        </li>
        <li @click="clickTab(3)" :class="[activeTab == 3 ? 'active' : '']">
          Abandono de checkout 
          (<span class="count">
            {{ quantity.abandonment_count }}
          </span>
          <div class="loaderPoints">
            <span></span>
            <span></span>
            <span></span>
          </div>)
          
        </li>
        <li @click="clickTab(4)" :class="[activeTab == 4 ? 'active' : '']">
          Cartão recusado 
          (<span class="count">
            {{ quantity.cardRefused_count }}
          </span>
          <div class="loaderPoints">
            <span></span>
            <span></span>
            <span></span>
          </div>)
          
        </li>
        <li @click="clickTab(5)" :class="[activeTab == 5 ? 'active' : '']">
          Pagos 
          (<span class="count">
            {{ quantity.paid_count }}
          </span>
          <div class="loaderPoints">
            <span></span>
            <span></span>
            <span></span>
          </div>)
          
        </li>
      </ul>
      <div class="categoriContainer">
        <p>ordenar por</p>

        <div class="form-check">
          <input  @click="changeOrder(0)" type="radio" name="orderBy" id="order-by-older" value="older" checked="">
          <label for="order-by-older">Mais antigos</label>
        </div>

        <div class="form-check">
          <input  @click="changeOrder(1)" type="radio" name="orderBy" id="order-by-recent" value="recent" >
          <label for="order-by-recent">Mais recentes</label>
        </div>

        <div class="form-check">
          <input  @click="changeOrder(2)" type="radio" name="orderBy" id="order-by-highier-ticket" value="ticket">
          <label for="order-by-highier-ticket">Ticket mais alto</label>
        </div>
        <div class="form-check">
          <input  @click="changeOrder(3)" type="radio" name="orderBy" id="order-by-lower-ticket" value="ticket">
          <label for="order-by-lower-ticket">Ticket mais baixo</label>
        </div>
      </div>
      <CardInformation 
        v-if="cardData != {}" 
        :active-tab-number="this.activeTab" 
        :data-card="this.cardData" 
        :whats-call="this.clickTab"
        :send-q-r-code="this.clickTab"
        :empty-state="this.emptyState"
      />
     
    </div>
  </div>
</template>

<style>
.containerTab .send-whats.secondary,
.containerTab .dateContent {
  display: none;
}

.containerTab .tabs-choose {
  margin-bottom: 0;
  display: flex;
  padding: 0px;
  margin: 0px;
  flex-wrap: wrap;
}

.containerTab .tabs-choose li {
  margin: 0px 0.5rem 0.75rem 0px;
  list-style: none;
  background: none;
  border: 0 solid transparent;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  font-size: 0.875rem;
  font-weight: 600;
  color: #3e4265;
  padding: 0.535rem 1rem;
  border-radius: 0.375rem;
  background-color: #eff2fc;
  user-select: none;
  cursor: pointer;
}

.containerTab .tabs-choose li span {
  font-size: 0.875rem;
  font-weight: 600;
  color: inherit;
  display: inline-flex;
  align-items: baseline;
}

.containerTab .tabs-choose li:hover {
  color: var(--converzap-green-faded) !important;
  border-color: transparent;
  isolation: isolate;
  border-radius: 0.375rem;
  background-color: #eff2fc;
}

.containerTab .tabs-choose li.active {
  color: var(--converzap-green-black) !important;
  background-color: var(--converzap-green-main) !important;
  border-color: var(--converzap-green-main);
  box-shadow: var(--converzap-btn-shadow);
  transition: all .3s ease;
  pointer-events: none;
  cursor: default;
}

.containerTab .tabs-choose li.active .loaderPoints span {
  background-color: var(--converzap-green-black)
}

.containerTab .tabs-content {
  background-color: #eee;
  margin: 0 auto;
  padding: 20px;
}
</style>
