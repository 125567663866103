<script>
import Tabs from '../components/Tabs.vue'
import SearchButton from '../components/SearchButton.vue'
import CopyrightFooter from '../components/CopyrightFooter.vue'
import ChangePassword from '../components/ChangePassword.vue'

export default {
    name: 'Atendimento',
    components: {
        Tabs,
        SearchButton,
        CopyrightFooter,
        ChangePassword
    }
}
</script>

<template>
    <section class="containerCenter">
        <ChangePassword/>
        <div class="containerRow">
            <h1>Atendimento</h1>
            <SearchButton/>
        </div>
        <h5>Novas mensagens</h5>
        <Tabs />
        <copyrightFooter/>
    </section>
</template>

<style>
    
</style>


