<script>
    import moment from 'moment'

export default {
    name: 'CardInformation',
    data() {
        return {
            statusCardData: {
                status: "waitingpayment",
                method: "billet",
                showQRCodeButton: false,
            },
        }
    },
    props: {
        activeTabNumber: {
            type: Number,
            default: 1
        },
        dataCard: {},
        whatsCall: {
            type: Function,
            required: true
        },
        sendQRCode: {
            type: Function,
            required: true
        },
        emptyState: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        badgePlatform(badge) {
            switch (badge) {
            case 'pepper':
                return require(`../assets/badgeCompany/${badge}.png`);
            case 'braip':
                return require(`../assets/badgeCompany/${badge}.png`);
            case 'kiwify':
                return require(`../assets/badgeCompany/${badge}.png`);
           }
        },
        formatTransactionMethod(method) {
           switch (method) {
            case 'pix':
                return 'Pix';
            case 'billet':
                return 'Boleto';
            case 'card':
                return 'Cartão';
           }
        },
        formatPhoneNumber(phone) {
            if(!phone) return
            return phone;
        },
        convertDateCard(dateTransaction) {
            const date = moment(dateTransaction, "YYYY-MM-DD HH:mm:ss").subtract(10800, 'seconds');
            const diff = moment().diff(date);
            const seconds = Math.floor(diff / 1000);
            const minutes = Math.floor(diff / (60 * 1000));
            const hours = Math.floor(diff / (60 * 60 * 1000));
            const days = Math.floor(diff / (24 * 60 * 60 * 1000));

            if (seconds < 60) {
                return `Há ${seconds} ${seconds === 1 ? 'segundo' : 'segundos'}`;
            } else if (minutes < 60) {
                return `Há ${minutes} ${minutes === 1 ? 'minuto' : 'minutos'}`;
            } else if (hours < 24) {
                const minutesDiff = Math.floor((diff % (60 * 60 * 1000)) / (60 * 1000));
                return `Há ${hours} ${hours === 1 ? 'hora' : 'horas'} e ${minutesDiff} ${minutesDiff === 1 ? 'minuto' : 'minutos'}`;
            } else if (days < 7) {
                return `Há ${days} ${days === 1 ? 'dia' : 'dias'}`;
            } else {
                return date.format("DD/MM/YYYY");
            }
        },
        convertDate(dateTransaction) {
            const date = moment(dateTransaction, "YYYY-MM-DD HH:mm:ss");
            return date.format("DD/MM/YYYY");
        },
        async makeCall(id) {
            $(".send-whats .loaderSpinner").show(200);
            await this.$axios.post(`/transactions/${id}/call`)
            .then( res => {
                window.open(res.data.data.whatsapp_url, "_blank");
                this.whatsCall(this.activeTabNumber)

            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                $(".send-whats .loaderSpinner").hide(200);
            });
        },
        async sendPaymentMethod(id) {
            $(".sendPayment .loaderSpinner").show(200);
            await this.$axios.post(`/transactions/${id}/barcode`)
            .then( res => {
                window.open(res.data.data.whatsapp_url, "_blank");
                this.sendQRCode(this.activeTabNumber);
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                $(".sendPayment .loaderSpinner").hide(200);
            });
        },
    },
    created() {
        this.convertDateCard()
        this.formatPhoneNumber()
    }

}
</script>

<template>
    <div class="cardContainer">
       
        <div v-if="emptyState" class="card-content fadeAnimation">
            <div class="card-header">
                <div class="informationCard">
                    <span class="tagCard" :class="[activeTabNumber == 1 ? 'boletoGenerated' : '']">Boleto gerado</span>
                    <span class="tagCard" :class="[activeTabNumber == 2 ? 'generatedPix' : '']">Pix Gerado</span>
                    <span class="tagCard" :class="[activeTabNumber == 3 ? 'checkoutAbandonment' : '']">Abandono de checkout</span>
                    <span class="tagCard" :class="[activeTabNumber == 4 ? 'purchaseRefused' : '']">Compra recusada</span>
                    <span class="tagCard" :class="[activeTabNumber == 5 ? 'paidOrder' : '']">Pedido pago</span>
                    <span class="timeCard">{{ convertDateCard(dataCard.transaction_date) }}</span>
                </div>

                <div class="informationCard">
                    <button v-if="dataCard.transaction_method == 'pix' || dataCard.transaction_method == 'billet'" @click="sendPaymentMethod(dataCard.id)" type="button" id="sendBillet" class="sendPayment btn btn-outline-converzap">
                        Enviar
                        <span class="loaderSpinner" style="display: none;"></span>
                    </button>

                    <button @click="makeCall(dataCard.id)"  type="button" id="sendWhats" class="send-whats btn btn-converzap">
                        Chamar
                        <span class="loaderSpinner" style="display: none;"></span>
                    </button>
                </div>
            </div>

            <div class="card-body">
                <div class="badgeCompany">
                    <img class="rounded-shadow" :src="badgePlatform(dataCard.platform)" :alt="dataCard.platform">
                </div>
                <div class="clientData">
                    <label>Dados do cliente</label>
                    <p>{{ dataCard.user_name }}</p>
                    <p>{{ dataCard.user_phone.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4') }}</p>
                    <p>E-mail: {{ dataCard.user_email }}</p>
                    <p>CPF: {{ dataCard.user_document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') }}</p>
                </div>

                <div class="clientData">
                    <label>{{ dataCard.product_name }}</label>
                    <p>Data: {{ convertDate(dataCard.transaction_date) }}</p>
                    <p>Transação: {{ dataCard.transaction_id }}</p>
                    <p>Forma pagamento: {{ formatTransactionMethod(dataCard.transaction_method) }}</p>
                    <p>Comissão: R$ {{ dataCard.transaction_price.toFixed(2).replace('.', ',') }}</p>
                </div>
            </div>
        </div>
    
        <div v-if="!emptyState" class="card-content empty-content fadeAnimation">
            <span class="loaderSpinner"></span>
            <div class="empty-status" style="display: none;">
                <span class="emptyState-icon"></span>
                <h3>Sem mensagens</h3>
            </div>
        </div>
    </div>
</template>

<style scoped>

.cardContainer {
    position: relative;
    min-height: 243px;
}
.card-content {
    padding: 1rem;
    margin: 0.25rem 0px 0.5rem;
    --bs-bg-opacity: 1;
    background-color: rgb(176 177 209 / 17%);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 0.5rem;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0.5rem 0px 1.5rem;
    width: 100%;
}

.dateContent {
    display: flex;
    margin-right: auto;
    margin: 0.5rem auto 1rem 0px;
}

.dateContent p,
.dateContent span {
    font-size: 0.875rem;
}

.dateContent span {
    color: var(--primary-dark);
    font-weight: 700;
}

.dateContent .attendant {
    position: relative;
    margin-left: 1.5rem;
}

.dateContent .attendant::before {
    content: '';
    background-image: url('../assets/bx-user-check.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 17px;
    height: 13px;
    display: inline-block;
    margin-right: 5px;
}

.card-header .informationCard .tagCard {
    border-radius: 50rem;
    color: #fff;
    font-weight: 700;
    font-size: 0.75rem;
    padding: 0.35em 0.6em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    display: none;
    margin-right: 1.5rem;
}

.tagCard.boletoGenerated,
.tagCard.generatedPix {
    background-color: var(--converzap-blue);
    display: inline-block !important;
}

.tagCard.checkoutAbandonment {
    color: #131022 !important;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
    display: inline-block !important;
}

.tagCard.purchaseRefused {
    display: inline-block !important;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.tagCard.paidOrder {
    display: inline-block !important;
    background-color: var(--converzap-green-dark);
}

.card-header .informationCard {
    display: flex;
    flex-wrap: wrap;
}


.card-header .informationCard .timeCard {
    position: relative;
    font-weight: 600;
    font-size: 0.75rem;
    display: inline-flex;
    align-items: center;
}

body.dark-mode .card-header .informationCard .timeCard {
    color: rgba(255, 255, 255, 0.7);
}

.card-header .informationCard .timeCard::before {
    content: '';
    background-image: url('../assets/bx-timer.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 5px;
    opacity: 0.6;
}

body.dark-mode .card-header .informationCard .timeCard::before {
    background-image: url('../assets/bx-timer-grey.svg');
}


.card-header .informationCard .send-whats {
    margin-left: 5px;
    text-decoration: none;
}

.card-header .informationCard .send-whats.btn-converzap,
#sendBillet {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-whats.btn-converzap::before,
#sendBillet::before {
    content: '';
    display: inline-block;
    background-image: url('../assets/bxl-whatsapp.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 15px;
    height: 20px;
    margin-right: 5px;
    transition: 0.15s;
    width: 15px;
    height: 15px;
}

.informationCard .loaderSpinner {
    margin: 0px -6px 0px 15px;
}

#sendBillet::before {
    background-image: url('../assets/bx-barcode-green.svg');
    background-position: center 1px;
}

#sendBillet:hover::before {
    background-image: url('../assets/bx-barcode.svg');
}

.card-body {
    width: 100%;
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.card-body .badgeCompany {
    margin: 0.25rem calc(var(--bs-gutter-x) * 0.5) 0px;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;

}

.card-body .badgeCompany img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #f7f7f7;
    object-fit: cover;
    object-position: center;
}

.card-body .clientData {
    flex: 0 0 auto;
    width: 33.33333333%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.card-body .clientData label {
    color: var(--primary-dark);
    font-weight: 700;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    display: inline-block;
}

body.dark-mode .card-body .clientData label {
    color: #fff
}

.card-body .clientData p {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    color: var(--bs-body-color);
}

body.dark-mode .card-body .clientData p {
    color: rgba(255, 255, 255, 0.7);
}

.empty-content {
    min-height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #F2F2F7;
    
}

body.dark-mode .empty-content {
    background-color: #2F2A3F;
}

.empty-status {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}


@media screen and (max-width: 995px) {

    .card-header .informationCard:first-child {
        margin-bottom: 2rem;
    }

    .card-body .badgeCompany {
        display: none;
    }

    .card-body .clientData {
        margin-top: 0.5rem;
        width: 50%;
    }
}

@media screen and (max-width: 610px) {
    .card-header {
        flex-direction: column;
        align-items: start;
    }

    .card-body {
        flex-direction: column;
        align-items: start;
    }

    .card-body .clientData {
        width: 100%;
    }

}
</style>