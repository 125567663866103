<script>
    import HeaderMain from '../components/HeaderMain.vue'


    export default {
        name: 'DefaultPage',
        components: {
            HeaderMain
        }
    }
</script>

<template>
    <div>
        <HeaderMain/>
        <main>
            <router-view></router-view>
        </main>
    </div>
</template>

<style src="@/styles/global.css" />