<script>

export default {
        name: 'SearchButton',
        data() {
            return {
                modalOpen: false,
            }
        },

        methods: {
           statusList(status) {
                document.getElementById("status").value = status;
                $('.dropdown-menu').removeClass('show')
            },

            openModal() {
                $('body').addClass('openModal')
                this.modalOpen = true;
            },
            
            closeModal() {
                $('body').removeClass('openModal')
                this.modalOpen = false;
            }
        },

        setup() {
            $(function() {                       
                $(".dropdownButton").click(function() {  
                    $(this).parent().toggleClass('showDropdown');  
                    if ($(this).parent().is('.showDropdown')) {
                        $('.showDropdown .dropdown-menu').addClass('show')
                        $('.showDropdown .dropdownButton').addClass('open')
                    } else {
                    $('.dropdownButton').removeClass('open')
                    $('.dropdown-menu').removeClass('show')
                }
                });
            });
        }
    }
</script>



<template>
    <div class="containerSearch">
        <button @click="openModal" class="btn btn-converzap btn-shadow">Buscar</button>
        <form action="/mensagens" class="containerModal">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>Buscar</h5>
                    <span @click="closeModal" class="closeButton secondaryClose"></span>
                </div>
    
                <div class="modal-body">
                    <div class="containerhalf">
                        <div class="containerInput">
                            <label>Nome do cliente</label>
                            <input id="name" name="client_name" type="text" placeholder="">
                        </div>
    
                        <div class="containerInput">
                            <label>Transação</label>
                            <input id="transaction" name="transaction_id" type="text" placeholder="">
                        </div>
                    </div>
    
                    <div class="containerhalf">
                        <div class="containerInput">
                            <label>E-mail</label>
                            <input id="email" name="client_email" type="email" placeholder="">
                        </div>
    
                        <div class="containerInput">
                            <label>Telefone</label>
                            <input id="tel" name="client_phone" type="tel" placeholder="">
                        </div>
                    </div>
    
                    <div class="containerhalf">
                        <div class="containerInput">
                            <label>Produtos</label>
                            <input id="product_name" name="product_name" type="text" placeholder="">
                        </div>

                        <!-- <div class="containerInput">
                            <label>Produtos</label>
                            <div class="dropdownButton">
                                <input type="text" name="product_name" id="product_name" placeholder="Produtos" readonly>
                            </div>
                            
                            <ul class="dropdown-menu">
                                <li>
                                    Produto 001
                                </li>
                            </ul>
                        </div> -->
                        
                        <div class="containerInput">
                            <label>Status</label>
                            <div class="dropdownButton">
                                <input type="text" name="status" id="status" placeholder="Status da transação" readonly>
                            </div>
                            
                            <ul class="dropdown-menu">
                                <li @click="statusList('Aguardando pagamento')">
                                    Aguardando pagamento
                                </li>
                                <li @click="statusList('Abandono de checkout')">
                                    Abandono de checkout
                                </li>
                                <li @click="statusList('Cartão recusado')">
                                    Cartão recusado
                                </li>
                                <li @click="statusList('Pago')">
                                    Pago
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
    
                <div class="modal-footer">
                    <button type="button" @click="closeModal" class="btn btn-secondary">Cancelar</button>
                    <button id="do-search" type="submit" class="btn btn-converzap btn-arrow">
                        Buscar
                    </button>
                </div>
            </div>
            <div @click="closeModal" class="fadeModal"></div>
        </form>
    </div>
    
</template>

<style scoped>
    .btn.btn-converzap.btn-shadow {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn.btn-converzap.btn-shadow::before {
        content: '';
        display: inline-block;
        background-image: url('../assets/grey-search.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        width: 17px;
        height: 20px;
        margin-right: 10px;
    }
    .modal-content {
        max-width: 800px;
        transition: transform 0.2s ease-out;
        transition: opacity 0.15s linear;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: var(--white);
        background-clip: padding-box;
        border: 0 solid rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
        box-shadow: 0 0.275rem 1.25rem rgb(19 16 34 / 5%), 0 0.25rem 0.5625rem rgb(19 16 34 / 3%);
        outline: 0;
        padding: 0px 1.5rem;
        z-index: 70;
    }

    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

   
    .modal-header h5 {
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 1.3;
        color: var(--primary-dark);
        text-transform: none;
    }

    .btn.btn-secondary {
        margin-right: 0.6rem;
    }
    body.dark-mode  .btn.btn-secondary {
        background-color: rgba(255, 255, 255, 0.1);
        color: #fff;
        border: none;
    }

    body.dark-mode  .btn.btn-secondary:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }

    
    @media screen and (max-width: 995px) {
    .modal-content {
    max-width: 500px;
    width: calc(100% - 20px);
    overflow-y: scroll;
    top: 10px;
    transform: translate(-50%);
    max-height: calc(100% - 20px);
    }

    .containerhalf {
    flex-direction: column;
    }
    .containerhalf .containerInput {
    width: 100%;
    }
    }

</style>