<script>

export default {
  data: function() {
    return {
      app_name: "Converzap"
    }
  }
}
</script>
<template>
  <div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>





