<script>
export default {
    name: 'ChangePassword',
    data() {
        return {
            errorBox: '',
            passwordType: 'password',
            confirmPasswordType: 'password',
            must_reset_password: null,
            userPassword: '',
            confirmUserPassword: ''
        }
    },
    created() {
        this.mustResetPassword()
    },
    methods: {
        notClose() {
            $('.modal-content-change-password').addClass('growingAnimation')
            .on("animationEnd oAnimationEnd mozAnimationEnd webkitAnimationEnd", 
            function(){
                $('.modal-content-change-password').removeClass('growingAnimation');
            });
        },
        sendForm(e) {
            
            if (!this.checkFields()) return

            this.loaderActive()
            const data = {
                password: this.userPassword,
            };
            
            this.$axios.post('/users/reset-password', data)
            .then( res => {
                if (res.data.success) {
                    localStorage.setItem('must_reset_password', 0);
                    this.must_reset_password = 0;
                }
            }).catch( (err) => {
                switch (err.response.status) {
                case 400:
                    localStorage.setItem('must_reset_password', 0);
                    this.must_reset_password = 0;
                break;
                }
            }).finally(() => {
                this.loaderDisabled()
            })
        },
        checkFields() {
            let inputPassword = document.querySelector('input[name="userPassword"]');

            if (this.userPassword == ""|| this.confirmUserPassword == "") {
                this.errorBox = "Preencha todos os campos"
                return false
            }
            if (this.userPassword != this.confirmUserPassword) {
                this.errorBox = 'As senhas digitadas não coincidem'
                return false
            } 
            if (inputPassword.value.length < 8) {
                this.errorBox = 'A senha deve ter mais de 8 caracteres'
                return false
            }

            return true
        },
        mustResetPassword() {
            if (localStorage.getItem("must_reset_password") == true) {
                this.must_reset_password = true
            }
        },
        loaderActive() {
            $("#confirm-button").prop('disabled', true);
            $(".loaderSpinner").show(200);
        },
        loaderDisabled() {
            $("#confirm-button").prop('disabled', false);
            $(".loaderSpinner").hide(200);
        },
    }
    
}
</script>


<template>
    <div v-if="must_reset_password">
        <form @submit="sendForm($event)" v-on:submit.prevent="onSubmit" class="modal-content-change-password">
            <div class="modal-body">
                <h4>Estamos quase...</h4>
                <p>Precisamos que adicione uma nova senha para continuar!</p>
                <div class="containerInput">
                    <label for="password">Senha</label>
                    <div class="password-toggle">
                        <input :type="passwordType" inputmode="text" id="password" v-model="userPassword" name="userPassword">
                        <div 
                            @click="passwordType == 'password' ? passwordType = 'text' : passwordType = 'password'" 
                            v-if="passwordType == 'password'" 
                            class="eye off"
                        ></div>
                        <div 
                            @click="passwordType == 'password' ? passwordType = 'text' : passwordType = 'password'" 
                            v-if="passwordType == 'text'" 
                            class="eye">
                        </div>
                    </div>
                </div>
    
                <div class="containerInput">
                    <label for="password-confirm">Confirme sua senha</label>
                    <div class="password-toggle">
                        <input :type="confirmPasswordType" id="password-confirm" v-model="confirmUserPassword" name="confirmPassword">
                        <div 
                            @click="confirmPasswordType == 'password' ? confirmPasswordType = 'text' : confirmPasswordType = 'password'"
                             v-if="confirmPasswordType == 'password'" 
                             class="eye off" >
                        </div>
                        <div 
                            @click="confirmPasswordType == 'password' ? confirmPasswordType = 'text' : confirmPasswordType = 'password'" 
                            v-if="confirmPasswordType == 'text'" 
                            class="eye" >
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div v-if="errorBox">
                    {{ errorBox }}
                    <span v-on:click="errorBox = false"></span>
                </div>
                <button type="submit" class="btn btn-converzap btn-arrow" id="confirm-button">
                    <span class="loaderSpinner" style="display: none;"></span>
                    Salvar
                </button>
            </div>
        </form>
        <div @click="notClose" class="fadeBlock"></div>
    </div>
</template>

<style scoped>
    .modal-content-change-password {

        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
        max-width: 800px;
        width: 100%;
        background-color: var(--white);
        border: 0 solid rgba(0, 0, 0, 0.2);
        border-radius: 0.5rem;
        outline: 0;
        z-index: 80;
        padding: 1.5rem;
        box-shadow: 0 0.275rem 1.25rem rgb(19 16 34 / 5%), 0 0.25rem 0.5625rem rgb(19 16 34 / 3%);
    }

    .modal-content-change-password .modal-body h4 {
        margin: 1.5rem 0px 0.5rem;
        line-height: 1.4;
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--primary-dark);
        letter-spacing: -0.45px;
    }

    .modal-content-change-password .modal-body p {
        margin: 0px 0px 1rem;
    }

    .modal-content-change-password .modal-footer {
        padding: 1.125rem 0px 0px;
        margin-top: 2rem;
        border-top: 1px solid #e2e5f1;
        border-bottom-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
    boyd.dark-mode .modal-content-change-password .modal-footer {
        border-color: rgba(255, 255, 255, 0.15);
    }
    .modal-content-change-password .modal-footer button {
        margin-left: auto;
    }
    
    .fadeBlock::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: 70;
        width: 100vw;
        height: 100vh;
        background-color: #000;
        opacity: 0.65;
        transition: 0.15s ease-in-out 0.15s;
    }

    .growingAnimation {
        animation: growing 0.4s alternate ease;
    }


    @keyframes growing {
        0%   {transform: translate(-50%, -50%) scale(1);}
        50% {transform: translate(-50%, -50%) scale(1.1);}
        100% {transform: translate(-50%, -50%) scale(1);}
    }

    .containerInput {
        position: relative;
    }

    .eye::before, .eye.off::before {
        top: 25px;
    }
    
    .eye::before {
        background-image: url('../assets/eye-black.svg')
    }

    .eye.off::before {
        background-image: url('../assets/eye-slash-black.svg')
    }

    body.dark-mode .eye::before {
        background-image: url('../assets/eye.svg')
    }

    body.dark-mode .eye.off::before {
        background-image: url('../assets/eye-slash.svg')
    }

    @media screen and (max-width: 995px) {
        .modal-content-change-password {
            margin: auto;
            max-width: calc(100% - 1rem);
            max-height: calc(100% - 1rem);
        }
    }

</style>